<template>
  <VApp>
    <SocioMainNavbar
      home-page-router-name="Home"
      :nav-items="navItems"
      :oidc-error="oidcError"
      :current-user="oidcUser"
      avatar-color="#A9C409"
      @authenticateOidcSilent="authenticateOidcSilent"
      @signOutOidc="signOutOidc"
    />
    <VMain id="app-main">
      <RouterView />
    </VMain>
  </VApp>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { useMainNavigations } from "@/composables/navigations/useMainNavigations";
import i18n from "@/setup/i18n";

export default {
  name: "Reporting",
  setup() {
    const { navItems } = useMainNavigations(i18n);

    return {
      navItems
    };
  },
  computed: {
    ...mapGetters("oidc", ["oidcError", "oidcUser"])
  },
  methods: {
    ...mapActions("oidc", ["authenticateOidcSilent", "signOutOidc"])
  }
};
</script>

<style lang="scss" scoped>
#app-main {
  background-color: #f8f9ff;
}
</style>
