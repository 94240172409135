import { ref } from "@vue/composition-api";

export function useMainNavigations(i18n) {
  const messages = {
    fr: {
      TEAMDISPLAY: "PROJETS"
    }
  };

  i18n.mergeLocaleMessage("fr", messages.fr);

  const navItems = ref([
    {
      routeName: "TeamDisplay",
      textLabel: i18n.t("TEAMDISPLAY"),
      displayLink: true
    }
  ]);

  return {
    navItems
  };
}
